import Layzr from 'layzr.js';
import isMobile from "libs/Utils/isMobile";

let instance;

window.layzrThreshold ??= isMobile.mobile() ? 50 : 100;

instance = Layzr({
  threshold: window.layzrThreshold
});

update();

function update() {
  instance.update().check().handlers(true);
}

document.addEventListener("lazy_images:update", update);
