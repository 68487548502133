import buildClickoutEvent from "../buildClickoutEvent";
import sendEvents from "../sendEvents";

export default function trackNonClickoutLinks(element: HTMLElement) {
  if (element.tagName === "A") {
    const sessionID = window.session_id ? `&session=${window.session_id}`:"";
    element.setAttribute('href', element.getAttribute('href')+sessionID)
    const Event = buildClickoutEvent(element);
    sendEvents(Event);
  }
}
